export default [
  {
    title: 'Finance',
    route: 'finance',
    icon: 'DollarSignIcon',
  },
  {
    title: 'CRM',
    route: 'crm',
    icon: 'CommandIcon',
  }
]
