export default [
  {
    header: 'Communication',
  },
  {
    title: 'SMS',
    icon: 'MailIcon',
    route: 'campaign-sms',
  },
  {
    title: 'Short Code',
    icon: 'InboxIcon',
    route: 'campaign-short-code',
  },
  {
    title: 'Whatsapp',
    icon: 'MessageCircleIcon',
    route: 'campaign-whatsapp',
  }
]
