export default [
  {
    title: 'Customers',
    route: 'customers',
    icon: 'UserIcon',
  },
  {
    title: 'Production',
    route: 'production',
    icon: 'DatabaseIcon',
  },
  {
    title: 'Catalogue',
    route: 'catalogue',
    icon: 'ShieldIcon',
  }
]
